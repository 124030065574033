<template>
  <div>
    <b-card no-body>
<!--      <b-card-header>-->
<!--        <AlbumImageNew name="image" :languages="languages"></AlbumImageNew>-->
<!--      </b-card-header>-->
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Title"
                label-for="title"
            >
              <b-form-input
                  id="name"
                  :value="searchFields.title"
                  @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Slug"
                label-for="slug"
            >
              <b-form-input
                  id="name"
                  :value="searchFields.slug"
                  @input="inputChange($event, 'slug')"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
                v-model="searchFields.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                :reduce="item => item.id"
                label="name"
                class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Pages',
      resourceName: 'pages',
      singularName: 'page',
      searchFields: {
        title: null,
        slug: null,
        status: null,
      },
      statuses: [
        {
          id: 'active',
          name: 'Active',
        },
        {
          id: 'inactive',
          name: 'In-Active',
        },
      ],
    }
  },
  computed: {
    languages() {
      return {
        en: 'English',
        ar: 'Arabic',
      }
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
