var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "pb-50"
  }, [_c('h5', [_vm._v(" Filters ")])]), _c('b-card-body', [_c('b-row', [_c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "value": _vm.searchFields.title
    },
    on: {
      "input": function input($event) {
        return _vm.inputChange($event, 'title');
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Slug",
      "label-for": "slug"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "value": _vm.searchFields.slug
    },
    on: {
      "input": function input($event) {
        return _vm.inputChange($event, 'slug');
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Status")]), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.statuses,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name"
    },
    model: {
      value: _vm.searchFields.status,
      callback: function callback($$v) {
        _vm.$set(_vm.searchFields, "status", $$v);
      },
      expression: "searchFields.status"
    }
  })], 1)], 1)], 1)], 1), _c('TableIndex', {
    attrs: {
      "title": _vm.title,
      "resource-name": _vm.resourceName,
      "singular-name": _vm.singularName,
      "filter-fields": _vm.searchFields
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }